<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="bbox plr13 BoxHeight pb30">
      <div class="text111 f17 textc pt20 fw700">{{ title }}</div>
      <div class="text999 f12 textc mt5">
        更新日期:{{ createDt | dataFormat }}
      </div>
      <div class="contents f14 text666"
           v-html="content"></div>
      <div class="flex mt20">
        <div class="textc flex1 ml15">
          <el-button type="primary"
                     @click="gotoExamination(examCode, title, time)"
                     class="wp100 f16 Btn">{{ examTypeName == "开始考试"?$t('my.startExam'):$t('my.startTest') }}</el-button>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/commonHead.vue";
import Footer from "../components/footer.vue";
import { Dialog, Toast } from "vant";
export default {
  components: { Header, Footer },
  data () {
    return {
      status: false,
      statusValue: "收藏",
      examCode: "",
      title: "",
      time: "",
      createDt: "",
      content: "",
      examType: "",
      examTypeName: "开始测试",
    };
  },
  methods: {
    gotoExamination (examCode, title, time) {
      //考试
      if ("2" == this.examType) {
        Dialog.confirm({
          title: "温馨提示",
          message: "当前考试只能参加一次，是否要现在开始参加考试？",
        })
          .then(() => {
            // on confirm
            this.toExam(examCode, title, time);
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.toExam(examCode, title, time);
      }
    },
    loadExamInfo () {
      var sendData = {};
      sendData.examCode = this.examCode;
      var that = this;
      this.$http
        .post("memberExam/queryExamInfo", sendData)
        .then(function (res) {
          console.log('test--res.data==',res.data);
          if (200 == res.data.code) {
            that.content = res.data.data.content;
            that.time = res.data.data.examTime;
            that.examType = res.data.data.type;
            that.createDt = res.data.data.createDt;
            if (that.examType == "2") {
              that.examTypeName = "开始考试";
            }
          }
        });
    },
    toExam (examCode, title, time) {
      var sendData = {};
      sendData.examCode = examCode;
      sendData.memberCode = localStorage.getItem("memberCode");
      var that = this;
      this.$http
        .post("memberExam/startMemberExam", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            var recordCode = res.data.data;
            localStorage.removeItem("examing");
            that.$router.push({
              path: "/examination",
              query: {
                examCode: examCode,
                title: title,
                time: time,
                recordCode: recordCode,
              },
            });
          } else {
            that.$notify.error({
              title: "提示",
              message: "参加考试失败：" + res.data.message,
              showClose: true,
            });
          }
        });
    },
  },
  created () {
    this.examCode = this.$route.query.examCode;
    this.title = this.$route.query.title;
    this.loadExamInfo();
  },
};
</script>

<style scoped>
.likeTool {
  width: 50px;
  height: 42px;
}
.likeTool div {
  line-height: 0;
}
</style>